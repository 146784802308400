<template>
  <!-- <section> -->
  <b-card no-body>
    <b-card-body>
      <b-card-title>
        <b-row>
          <b-col>
            <div>Voucher Paket : {{ ebook.name }}</div>
          </b-col>
          <b-col>
            <div align="right">
              <b-button variant="outline-primary" v-b-modal.modal-sm @click="ModalShow()" class="btn-icon">
                <feather-icon icon="PlusIcon" class="mr-25" />Tambah</b-button>
            </div>
            <b-modal v-model="Modal" id="modal-tambah" centered size="sm" :title="titleModal">
              <b-card-text>
                <b-form>
                  <b-form-group label="Voucher" label-for="Voucher">
                    <b-form-select :options="voucherData" id="Voucher" v-model="form.voucher_id" placeholder="Voucher" />
                  </b-form-group>
                </b-form>
              </b-card-text>

              <template #modal-footer>
                <div class="w-100">
                  <p class="float-left mb-0"></p>

                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="outline-success"
                    class="mr-1 btn-icon" @click.prevent="Tambah">
                    <feather-icon icon="SaveIcon" class="mr-25" />{{ label || "Tambah" }}
                  </b-button>
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary"
                    class="float-right btn-icon" @click="Modal = false">
                    <feather-icon icon="LogOutIcon" class="mr-25" />Tutup
                  </b-button>
                </div>
              </template>
            </b-modal>
          </b-col></b-row>
      </b-card-title>
      <b-card-text class="blog-content-truncate">
        <b-row>
          <b-col cols="12">
            <b-table striped small hover responsive :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter"
              :filter-included-fields="filterOn" @filtered="onFiltered" :fields="fields" :items="ebookVoucherData">
              <!-- A virtual column -->
              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>

              <!-- A custom formatted column -->
              <template #cell(action)="row">
                <!-- <b-button title="Ubah" variant="outline-info" class="btn-icon" @click="ModalUbah(row.item)"> <feather-icon icon="EditIcon" /></b-button>
                <b-button title="Hapus" variant="outline-danger" class="btn-icon" @click="ModalHapus(row.item)"> <feather-icon icon="TrashIcon" /></b-button> -->
                <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                  <template v-slot:button-content>
                    <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                  </template>
                  <b-dropdown-item @click="ModalUbah(row.item)">
                    <feather-icon icon="Edit2Icon" class="mr-50" />
                    <span>Edit</span>
                  </b-dropdown-item>
                  <!-- <b-dropdown-item :to="{ name: 'admin-ebook-detail', params: { id: row.item.id } }">
                        <feather-icon icon="EyeIcon" class="mr-50" />
                        <span>Detail</span>
                      </b-dropdown-item> -->
                  <b-dropdown-item @click="ModalHapus(row.item)">
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span>Hapus</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
              <!-- A custom formatted column -->
              <template #cell(Popularity)="data">
                <b-progress :value="data.value.value" max="100" :variant="data.value.variant" striped />
              </template>

              <template #cell(color)="data">
                <b-badge :variant="data.item.color">
                  {{ data.item.color }}
                </b-badge>
              </template>

              <!-- A virtual composite column -->
              <template #cell(price)="data">
                {{ "$" + data.value }}
              </template>
              <template #cell(voucher.value)="data">
                {{
                  data.item.voucher.type == "nominal"
                  ? "Rp" + formatRupiah(data.item.voucher.value)
                  : data.item.voucher.value + "%"
                }}
              </template>
              <template #cell(voucher.type)="data">
                {{
                  data.item.voucher.type == "nominal" ? "Nominal (Rp)" : "Persentase (%)"
                }}
              </template>

              <!-- Optional default data cell scoped slot -->
              <template #cell()="data">
                {{ data.value }}
              </template>
            </b-table>
            <!-- <b-pagination
              v-if="record !== 0"
              :total="Math.ceil((total * 1) / itemsPerPage)"
              v-model="currentPage"
              @change="staf()"
            /> -->
            <!-- :prev-icon="isRTL ? 'arrow_forward' : 'arrow_back'"
              :next-icon="isRTL ? 'arrow_back' : 'arrow_forward'" -->

            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm"
              class="my-0" />
          </b-col>
        </b-row>
      </b-card-text>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BTable,
  BCard,
  BCardText,
  BCardTitle,
  BCardBody,
  BPagination,
  BProgress,
  BBadge,
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BFormRadioGroup,
  BFormTextarea,
  VBTooltip,
  BDropdownItem,
  BDropdown,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  props: ["ebook"],
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BTable,
    BPagination,
    BProgress,
    BBadge,
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BFormRadioGroup,
    BFormTextarea,
    VBTooltip,
    ToastificationContent,
    BDropdownItem,
    BDropdown,
  },
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      id: null,
      activeAction: null,
      label: "Simpan",
      //Data
      ebookVoucherData: [],
      voucherData: [],
      // ebook: {
      //   name: null,
      // },
      form: {
        id: null,
        voucher_id: null,
      },
      //Modal
      titleModal: null,
      Modal: false,
      ModalEdit: false,
      //Table
      fields: [
        { key: "index", label: "No" },
        { key: "voucher.name", label: "Voucher", sortable: true },
        { key: "voucher.description", label: "Deskripsi", sortable: true },
        { key: "voucher.type", label: "Tipe", sortable: true },
        { key: "voucher.value", label: "Nilai", sortable: true },
        { key: "action", label: "#" },
      ],
      selected: [],
      total: [],
      record: [],
      optFilter: [
        { id: "text", value: "Voucher" },
        // { id: "nip", value: "NIP" },
      ],
      perPage: 10,
      pageOptions: [10, 30, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      // filter: "Voucher Paket",
      search: null,
      idd: null,
    };
  },
  watch: {},
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    ModalShow() {
      this.id = null;
      this.activeAction = "tambah";
      this.titleModal = "Tambah Voucher Paket";
      this.Modal = true;
      // this.ModalEdit = false;
    },
    ModalUbah(item) {
      this.id = item.id;
      this.form = item;
      this.activeAction = "ubah";
      this.titleModal = "Ubah Voucher Paket";
      this.Modal = true;
      // this.ModalEdit = true;
    },
    ModalHapus(data) {
      this.$swal({
        title: "Anda Yakin?",
        text: "Data tidak bisa dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          data.fungsi = 1; //soft delete
          this.$store
            .dispatch("e-book/CUDVoucherEbook", [data])
            .then(() => {
              this.getDataPaketVoucher();
              this.displaySuccess({
                text: "Data berhasil dihapus",
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
          this.$swal({
            icon: "success",
            title: "Terhapus!",
            text: "Data berhasil dihapus.",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Batal",
            text: "Data aman, kamu batal menghapus data :)",
            icon: "error",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    pesanGagal() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    Tambah() {
      if (
        (this.form.voucher_id == null && this.form.voucher_id == null) ||
        (this.form.voucher_id == "" && this.form.voucher_id == "")
      ) {
        this.pesanGagal();
        return false;
      }
      let payload = {
        // text: this.form.text,
        voucher_id: this.form.voucher_id,
        ebook_id: this.$route.params.id,
      };

      if (this.id) {
        payload.id = this.id;
      }

      this.$store
        .dispatch("e-book/CUDVoucherEbook", [payload])
        .then(() => {
          if (this.activeAction == "tambah") {
            this.id = null;
          }
          // this.ModalEdit = false;
          this.getDataPaketVoucher();
          this.displaySuccess({
            text: "Voucher Paket berhasil di" + this.activeAction,
          });
          this.form.text = null;
          this.Modal = false;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    async getDataPaketVoucher() {
      const payload = {
        // sekolah_id: localStorage.getItem("sekolah"),
        // filter: this.filter !== null ? this.filter.id : "text",
        // search: this.search,
        // start: (this.currentPage - 1) * this.itemsPerPage,
        // length: this.itemsPerPage,
        ebook_id: this.$route.params.id,
      };
      this.$store
        .dispatch("e-book/indexVoucherEbook", payload)
        .then((response) => {
          let ebookVoucherData = response.data.data;
          this.ebookVoucherData = ebookVoucherData;
          this.totalRows = this.ebookVoucherData.length;
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        });
    },
    // async getDataById() {
    //   this.$store
    //     .dispatch("paketTryout/indexId", this.$route.params.id)
    //     .then((response) => {
    //       let ebook = response.data;
    //       this.ebook = ebook;
    //     })
    //     .catch((error) => {
    //       this.$root.$emit("errorData", error);
    //     });
    // },

    async getVoucher() {
      const payload = {
        // sekolah_id: localStorage.getItem("sekolah"),
        // filter: this.filter !== null ? this.filter.id : "text",
        // search: this.search,
        // start: (this.currentPage - 1) * this.itemsPerPage,
        // length: this.itemsPerPage,
        // paket_id: this.$route.params.id,
      };
      this.$store
        .dispatch("voucher/index", payload)
        .then((response) => {
          let voucherData = response.data.data;
          voucherData.map((item) => {
            item.value = item.id;
            item.text = item.name;
          });
          this.voucherData = voucherData;
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        });
    },
  },
  created() { },
  mounted() {
    this.getDataPaketVoucher();
    this.getVoucher();
  },
};
</script>

<style></style>
