<template>
  <b-overlay :show="loading">
    <div align="left">
      <b-button
        variant="outline-danger"
        :to="{ name: 'admin-ebook' }"
        class="btn-icon mb-1"
      >
        <feather-icon icon="ArrowLeftIcon" class="mr-25" />Kembali</b-button
      >
    </div>
    <b-tabs>
      <b-tab active>
        <!-- title -->
        <template #title>
          <feather-icon icon="ArchiveIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Detail</span>
        </template>
        <b-card no-body>
          <b-card-body>
            <b-row v-if="data" class="">
              <b-col cols="12" md="7">
                <b-card-text class="item-company">
                  <b-badge pill class="mr-75" variant="primary">
                    {{ data.category.name }}
                  </b-badge>
                </b-card-text>
                <h4 class="mb-3">{{ data.name }}</h4>

                <b-card-text>Tentang buku ini </b-card-text>

                <b-card-text>
                  <span v-html="data.description"></span
                ></b-card-text>
              </b-col>

              <b-col
                cols="12"
                md="5"
                class="d-flex align-items-center justify-content-center mb-2 mb-md-0"
              >
                <b-card>
                  <b-card class="border">
                    <center>
                      <b-img
                        :src="apiFile + data.photo"
                        style="width: 70%"
                        :alt="`Image of ${data.name}`"
                        class="data-img"
                        fluid
                      />
                    </center>
                    <h6 class="mt-1">Harga</h6>
                    <h3 class="text-primary mb-1">
                      Rp{{ formatRupiah(data.price) }}
                    </h3>
                    <feather-icon icon="CalendarIcon" class="mr-50" />
                    <small class="font-weight-bold"
                      >Terbit {{ data.publication_year }}</small
                    >
                    <feather-icon icon="BookOpenIcon" class="mr-50 ml-2" />
                    <small class="font-weight-bold"
                      >{{ data.num_page }} halaman</small
                    >
                  </b-card>
                  <div class="">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-primary"
                      class="btn-cart btn-block mt-1"
                      @click="$refs.bacaFile.click()"
                    >
                      <span>
                        <feather-icon icon="EyeIcon"></feather-icon> Lihat</span
                      >
                    </b-button>
                    <a
                      ref="bacaFile"
                      :href="apiFile + data.file_ebook.file"
                      target="_blank"
                    ></a>
                  </div>
                </b-card>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="CheckCircleIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Siswa yang membeli</span>
        </template>
        <buyer :ebook="data" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="PercentIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Voucher</span>
        </template>
        <voucher :ebook="data" />
      </b-tab>
    </b-tabs>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BMedia,
  BMediaAside,
  BMediaBody,
  BImg,
  BAlert,
  BLink,
  BTabs,
  BTab,
  BRow,
  BCol,
  BTable,
  BCard,
  BCardText,
  BCardTitle,
  BCardBody,
  BPagination,
  BProgress,
  BBadge,
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormFile,
  BInputGroup,
  BInputGroupAppend,
  BFormRadioGroup,
  BFormTextarea,
  VBTooltip,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import Buyer from "./buyer/Index.vue";
import Voucher from "./voucher/Index.vue";

export default {
  components: {
    BOverlay,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    BAlert,
    BLink,
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BTable,
    BPagination,
    BProgress,
    BBadge,
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormFile,
    BInputGroup,
    BInputGroupAppend,
    BFormRadioGroup,
    BFormTextarea,
    VBTooltip,
    ToastificationContent,
    flatPickr,
    Buyer,
    Voucher,
  },

  data() {
    return {
      loading: false,
      data: {},
    };
  },
  methods: {
    getData() {
      this.loading = true;
      let id = this.$route.params.id;

      this.$store
        .dispatch("e-book/detail", id)
        .then((res) => {
          this.loading = false;
          this.data = res.data;
        })
        .catch((err) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error `,
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.message,
            },
          });
        });
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style></style>
