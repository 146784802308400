var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('div', {
    attrs: {
      "align": "left"
    }
  }, [_c('b-button', {
    staticClass: "btn-icon mb-1",
    attrs: {
      "variant": "outline-danger",
      "to": {
        name: 'admin-ebook'
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "ArrowLeftIcon"
    }
  }), _vm._v("Kembali")], 1)], 1), _c('b-tabs', [_c('b-tab', {
    attrs: {
      "active": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "ArchiveIcon",
            "size": "18"
          }
        }), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Detail")])];
      },
      proxy: true
    }])
  }, [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-body', [_vm.data ? _c('b-row', {}, [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('b-card-text', {
    staticClass: "item-company"
  }, [_c('b-badge', {
    staticClass: "mr-75",
    attrs: {
      "pill": "",
      "variant": "primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.category.name) + " ")])], 1), _c('h4', {
    staticClass: "mb-3"
  }, [_vm._v(_vm._s(_vm.data.name))]), _c('b-card-text', [_vm._v("Tentang buku ini ")]), _c('b-card-text', [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.data.description)
    }
  })])], 1), _c('b-col', {
    staticClass: "d-flex align-items-center justify-content-center mb-2 mb-md-0",
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('b-card', [_c('b-card', {
    staticClass: "border"
  }, [_c('center', [_c('b-img', {
    staticClass: "data-img",
    staticStyle: {
      "width": "70%"
    },
    attrs: {
      "src": _vm.apiFile + _vm.data.photo,
      "alt": "Image of ".concat(_vm.data.name),
      "fluid": ""
    }
  })], 1), _c('h6', {
    staticClass: "mt-1"
  }, [_vm._v("Harga")]), _c('h3', {
    staticClass: "text-primary mb-1"
  }, [_vm._v(" Rp" + _vm._s(_vm.formatRupiah(_vm.data.price)) + " ")]), _c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "CalendarIcon"
    }
  }), _c('small', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Terbit " + _vm._s(_vm.data.publication_year))]), _c('feather-icon', {
    staticClass: "mr-50 ml-2",
    attrs: {
      "icon": "BookOpenIcon"
    }
  }), _c('small', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(_vm.data.num_page) + " halaman")])], 1), _c('div', {}, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "btn-cart btn-block mt-1",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$refs.bacaFile.click();
      }
    }
  }, [_c('span', [_c('feather-icon', {
    attrs: {
      "icon": "EyeIcon"
    }
  }), _vm._v(" Lihat")], 1)]), _c('a', {
    ref: "bacaFile",
    attrs: {
      "href": _vm.apiFile + _vm.data.file_ebook.file,
      "target": "_blank"
    }
  })], 1)], 1)], 1)], 1) : _vm._e()], 1)], 1)], 1), _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "CheckCircleIcon",
            "size": "18"
          }
        }), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Siswa yang membeli")])];
      },
      proxy: true
    }])
  }, [_c('buyer', {
    attrs: {
      "ebook": _vm.data
    }
  })], 1), _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "PercentIcon",
            "size": "18"
          }
        }), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Voucher")])];
      },
      proxy: true
    }])
  }, [_c('voucher', {
    attrs: {
      "ebook": _vm.data
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }