<template>
  <!-- <section> -->
  <b-card no-body>
    <b-card-body>
      <b-card-title>
        <b-row>
          <b-col>
            <div>Siswa yang sudah membeli Ebook {{ ebook.name }} :</div>
          </b-col>
        </b-row>
      </b-card-title>
      <b-card-text class="blog-content-truncate">
        <b-row>
          <b-col md="3" sm="4" class="my-1">
            <b-form-group class="mb-0">
              <label class="d-inline-block text-sm-left mr-50">Per page</label>
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                class="w-50"
              />
            </b-form-group>
          </b-col>
          <b-col md="4" sm="8" class="my-1">
            <b-form-group
              label="Sort"
              label-cols-sm="2"
              label-align-sm="right"
              label-size="sm"
              label-for="sortBySelect"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                  class="w-50"
                >
                  <template v-slot:first>
                    <option value="">-- none --</option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                  class="w-30"
                >
                  <option :value="false">Asc</option>
                  <option :value="true">Desc</option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="5" class="my-1">
            <b-form-group label-for="filterInput" class="mb-0">
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Search......"
                />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-table
              striped
              small
              hover
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              :fields="fields"
              :items="buyer"
            >
              <!-- A virtual column -->
              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>

              <!-- A custom formatted column -->
              <template #cell(action)="row">
                <b-button
                  title="Ubah"
                  variant="outline-info"
                  class="btn-icon"
                  @click="ModalUbah(row.item)"
                >
                  <feather-icon icon="EditIcon"
                /></b-button>
                <b-button
                  title="Hapus"
                  variant="outline-danger"
                  class="btn-icon"
                  @click="ModalHapus(row.item)"
                >
                  <feather-icon icon="TrashIcon"
                /></b-button>
              </template>
              <!-- A custom formatted column -->
              <template #cell(Popularity)="data">
                <b-progress
                  :value="data.value.value"
                  max="100"
                  :variant="data.value.variant"
                  striped
                />
              </template>

              <template #cell(color)="data">
                <b-badge :variant="data.item.color">
                  {{ data.item.color }}
                </b-badge>
              </template>

              <!-- A virtual composite column -->
              <template #cell(price)="data">
                {{ "$" + data.value }}
              </template>

              <!-- Optional default data cell scoped slot -->
              <template #cell()="data">
                {{ data.value }}
              </template>
            </b-table>
            <!-- <b-pagination
              v-if="record !== 0"
              :total="Math.ceil((total * 1) / itemsPerPage)"
              v-model="currentPage"
              @change="staf()"
            /> -->
            <!-- :prev-icon="isRTL ? 'arrow_forward' : 'arrow_back'"
              :next-icon="isRTL ? 'arrow_back' : 'arrow_forward'" -->

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
              size="sm"
              class="my-0"
            />
          </b-col>
        </b-row>
      </b-card-text>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BTable,
  BCard,
  BCardText,
  BCardTitle,
  BCardBody,
  BPagination,
  BProgress,
  BBadge,
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BFormRadioGroup,
  BFormTextarea,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  props: ["paketDataId"],
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BTable,
    BPagination,
    BProgress,
    BBadge,
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BFormRadioGroup,
    BFormTextarea,
    VBTooltip,
    ToastificationContent,
  },
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  props: ["ebook"],
  data() {
    return {
      id: null,
      activeAction: null,
      label: "Simpan",
      //Data
      buyer: [],
      paketDataId: {
        name: null,
      },
      form: {
        id: null,
        question: null,
        answer: null,
        paket_id: null,
      },
      //Modal
      titleModal: null,
      Modal: false,
      ModalEdit: false,
      //Table
      fields: [
        { key: "index", label: "No" },
        { key: "name", label: "Nama", sortable: true },
        // { key: "action", label: "#" },
      ],
      selected: [],
      total: [],
      record: [],
      optFilter: [
        { id: "question", value: "Pertanyaan" },
        { id: "answer", value: "Jawaban" },
        // { id: "nip", value: "NIP" },
      ],
      perPage: 10,
      pageOptions: [10, 30, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      // filter: "FAQ Paket",
      search: null,
      idd: null,
    };
  },
  watch: {},
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    ModalShow() {
      this.id = null;
      this.activeAction = "tambah";
      this.titleModal = "Tambah FAQ Paket";
      this.Modal = true;
      // this.ModalEdit = false;
    },
    ModalUbah(item) {
      this.id = item.id;
      this.form = item;
      this.activeAction = "ubah";
      this.titleModal = "Ubah FAQ Paket";
      this.Modal = true;
      // this.ModalEdit = true;
    },
    ModalHapus(data) {
      this.$swal({
        title: "Anda Yakin?",
        text: "Data tidak bisa dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          data.fungsi = 1; //soft delete
          this.$store
            .dispatch("paketTryout/CUDFaqPaket", [data])
            .then(() => {
              this.getDataBuyer();
              this.displaySuccess({
                text: "Data berhasil dihapus",
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
          this.$swal({
            icon: "success",
            title: "Terhapus!",
            text: "Data berhasil dihapus.",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Batal",
            text: "Data aman, kamu batal menghapus data :)",
            icon: "error",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    pesanGagal() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    Tambah() {
      if (
        (this.form.question == null && this.form.answer == null) ||
        (this.form.question == "" && this.form.answer == "")
      ) {
        this.pesanGagal();
        return false;
      }
      let payload = {
        question: this.form.question,
        answer: this.form.answer,
        paket_id: this.$route.params.id,
      };

      if (this.id) {
        payload.id = this.id;
      }

      this.$store
        .dispatch("paketTryout/CUDFaqPaket", [payload])
        .then(() => {
          if (this.activeAction == "tambah") {
            this.id = null;
          }
          // this.ModalEdit = false;
          this.getDataBuyer();
          this.displaySuccess({
            text: "FAQ Paket berhasil di" + this.activeAction,
          });
          this.form.question = null;
          this.form.answer = null;
          this.Modal = false;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    async getDataBuyer() {
      const params = {
        ebook_id: this.$route.params.id,
      };
      this.$store
        .dispatch("e-book/ebookBuyer", params)
        .then((response) => {
          let buyer = response.data.data;
          this.buyer = buyer;
          this.totalRows = this.buyer.length;
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        });
    },
  },
  created() {},
  mounted() {
    this.getDataBuyer();
  },
};
</script>

<style></style>
